import {useMutation, useQuery} from "@apollo/client";

import {message} from "antd";
import {QUESTIONS, SELECTED_QUESTIONS} from "../apollo/queries/questions";
import {UPDATE_QUESTION} from "../apollo/mutations/questions";
import {useEffect, useState} from "react";

function useNewQuestions(_id) {
  const {data, loading} = useQuery(QUESTIONS, {
    variables: {event: {_id:"62021920da66518b98fdb9b6"}},
    fetchPolicy: "cache-and-network",
    pollInterval:2000
  })
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  useEffect(() => {
    data && setFilteredQuestions(data?.questionsViews ?? [])
  }, [data]);
  
  function filter(e) {
    setTimeout(
      setFilteredQuestions(
        data?.questionsViews.filter((question) => {
          return (
            question.communication.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
          );
        })
      )
    );
  }
  
  return {
    questions: filteredQuestions,
    loading,
    filter
  }
}

function useUpdateQuestion() {
  const [updateOneQuestion, {loading, error}] = useMutation(UPDATE_QUESTION)
  console.log(error)
  
  async function updateQuestion(_id, state) {
    try {
      await updateOneQuestion({
        variables: {
          query: {_id},
          set: {state}
        },
        refetchQueries: [
          {
            query: QUESTIONS,
            variables: {query: {event: {_id: "62021920da66518b98fdb9b6"}}}
          },
        ]
      })
      message.success("La question a été mise à jour!")
    } catch (e) {
      console.log(e)
      message.error("La question n'a pas été mise à jour!")
    }
    
  }
  
  return {
    updateQuestion,
    loading
  }
}


export {
  useNewQuestions,
  useUpdateQuestion,
}