import React from 'react';
import {Card, Input, Space,Typography} from "antd";
import QuestionsGroup from "./questionsGroup";
import {SearchOutlined} from "@ant-design/icons";
import {useNewQuestions} from "../hooks/questions";
const {Title}=Typography
export default function NewQuestions({_id}) {
  const {questions, filter} = useNewQuestions(_id)
  return (
    <Card
      style={{margin:30}}
      title={<Title level={2}>Liste des question</Title>}
    >
      <Space direction="vertical" style={{width: "100%"}}>
        <Input
          onChange={filter}
          placeholder="Rechercher une communication"
          allowClear
          suffix={
            <SearchOutlined style={{color: "rgba(0,0,0,.45)"}}/>
          }
          style={{width: "30%"}}
        />
        {questions.map(question => <QuestionsGroup group={question} _id={_id}/>)}
      </Space>
    </Card>
    
  );
}