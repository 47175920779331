import React from 'react';
import {Button, Card, List, Popconfirm, Typography,} from "antd";
import {CheckOutlined, DownloadOutlined} from "@ant-design/icons";
import {useUpdateQuestion} from "../hooks/questions";

const {Title} = Typography

export default function QuestionsGroup({group,_id}) {
  const {updateQuestion,loading}=useUpdateQuestion(_id)
  
  function render(item) {
    return <List.Item
      actions={[
        <Button
          type="primary"
          icon={<CheckOutlined/>}
          loading={loading}
          onClick={()=>updateQuestion(item._id,"selected")}
        >
          Sélectionner
        </Button>
        ,
        <Popconfirm
          title={"Etes-vous sûr de vouloir archiver?"}
          //onConfirm={() => deleteSpeaker(item._id)}
          okText="Oui"
          cancelText="Non"
        >
          <Button
            loading={loading}
            icon={<DownloadOutlined/>}
            onClick={()=>updateQuestion(item._id,"archived")}
          >
            Archiver
          </Button>
        </Popconfirm>
      ]}
    >
      <List.Item.Meta
        title={item.content}
        description={item.fullName}
      />
    </List.Item>
    
  }
  
  return (
    <Card
      title={<Title level={4}>{group.communication}</Title>}
    >
      <List
        itemLayout="horizontal"
        dataSource={group.questions}
        renderItem={render}
      />
    </Card>
  );
}